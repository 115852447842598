let baseUrl = "https://api.menuchic.com";
let apiUrl = "https://api.menuchic.com/api/v1";
let client = "general";
let clientSubdomain = "menuchic.com";
let jsonFolder = "staticV4";
let qrcodeUrl = "https://qrcode.foound.com";
let imgUrl = "https://assets.foound.com";
let assetsUrl = "https://assets.foound.com";
let i18Url = "https://assets.foound.com/i18n";

const siteConfig = {
  siteName: "Foound",
  siteIcon: "ion-flash",
  footerText: "copyright ©️ Foound 2019-2023",
};

if (process.env.REACT_APP_CLIENT && process.env.REACT_APP_CLIENT === "elior") {
  baseUrl = "https://api.elior.app";
  apiUrl = "https://api.elior.app/api/v1";
  qrcodeUrl = "https://api.elior.app";
  siteConfig.siteName = "Elior.app";
  siteConfig.footerText = "copyright ©️ Elior 2019-2023";
  client = "elior";
  clientSubdomain = "elior.app";
  //clientLogoUrl = 'https://assets.foound.com/logo/eliorLogo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "eliorpreprod"
) {
  baseUrl = "https://apielior.foound.com";
  apiUrl = "https://apielior.foound.com/api/v1";
  qrcodeUrl = "https://apielior.foound.com";
  siteConfig.siteName = "Elior.app";
  siteConfig.footerText = "copyright ©️ Elior preprod 2019-2023";
  client = "elior";
  clientSubdomain = "elior.app";
  //clientLogoUrl = 'https://assets.foound.com/logo/eliorLogo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "netto"
) {
  baseUrl = "https://api.foound.it";
  apiUrl = "https://api.foound.it/api/v1";
  qrcodeUrl = "https://api.foound.it";
  siteConfig.siteName = "Netto.app";
  siteConfig.footerText = "copyright ©️ Netto 2023";
  client = "netto";
  clientSubdomain = "menuchic.it";
  //imgUrl = "https://foound-netto.s3.eu-west-3.amazonaws.com";
  imgUrl = "https://assets.foound.com/netto";
  assetsUrl = "https://assets.foound.com/netto";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "regioneo"
) {
  baseUrl = "https://api.regioneo.com";
  apiUrl = "https://api.regioneo.com/api/v1";
  qrcodeUrl = "https://api.regioneo.com";
  siteConfig.siteName = "Regioneo.com";
  siteConfig.footerText = "copyright ©️ Regioneo 2024";
  client = "regioneo";
  clientSubdomain = "regioneo.com";
  imgUrl = "https://assets.foound.com/regioneo";
  assetsUrl = "https://assets.foound.com/regioneo";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "nettodev"
) {
  baseUrl = "https://apidev.foound.it";
  apiUrl = "https://apidev.foound.it/api/v1";
  qrcodeUrl = "https://apidev.foound.it";
  siteConfig.siteName = "NettoDev.app";
  siteConfig.footerText = "copyright ©️ NettoDev 2023";
  client = "nettodev";
  clientSubdomain = "foound.me";
  imgUrl = "https://assets.foound.com/netto";
  assetsUrl = "https://assets.foound.com/netto";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "dev"
) {
  baseUrl = "https://api.menuchic.net";
  apiUrl = "https://api.menuchic.net/api/v1";
  qrcodeUrl = "https://api.menuchic.net";
  siteConfig.siteName = "Dev";
  siteConfig.footerText = "copyright ©️ Dev 2023";
  client = "dev";
  clientSubdomain = "menuchic.net";
  imgUrl = "https://assets-dev.foound.com";
  assetsUrl = "https://assets-dev.foound.com";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "go4art"
) {
  baseUrl = "https://api.go-4.art";
  apiUrl = "https://api.go-4.art/api/v1";
  qrcodeUrl = "https://api.go-4.art";
  siteConfig.siteName = "go4art";
  siteConfig.footerText = "copyright ©️ Go4art 2023";
  client = "go4art";
  clientSubdomain = "go-4.art";
  //imgUrl = "https://foound-netto.s3.eu-west-3.amazonaws.com";
  imgUrl = "https://assets.foound.com/go4art";
  assetsUrl = "https://assets.foound.com/go4art";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
} else if (
  process.env.REACT_APP_CLIENT &&
  process.env.REACT_APP_CLIENT === "local"
) {
  baseUrl = "https://api.test";
  apiUrl = "https://api.test/api/v1";
  qrcodeUrl = "https://api.test";
  siteConfig.siteName = "Local";
  siteConfig.footerText = "copyright ©️ Dev 2023";
  client = "dev";
  clientSubdomain = "menuchic.net";
  imgUrl = "https://assets.foound.com/dev";
  assetsUrl = "https://assets.foound.com/dev";
  //clientLogoUrl = 'https://assets.foound.com/logo/yeswao_logo.jpeg';
}

let hostname;

if (document.location.hostname === "localhost") {
  hostname = "menuchic";
} else {
  const tmp = document.location.hostname.split(".");
  hostname = tmp[1];
}

console.log("API_URL");
console.log(apiUrl);
console.log(siteConfig.siteName);
console.log(client);
console.log(hostname);

const clientLogoUrl = `${assetsUrl}/back/logo.png`;
const clientHomeUrl = `${assetsUrl}/back/home.jpg`;
const passwordForgotUrl = `${assetsUrl}/back/password_forgot.jpg`;

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "french";
const AlgoliaSearchConfig = {
  appId: "",
  apiKey: "",
};

const firebaseConfig = {
  apiKey: "AIzaSyDUnyWH7xV3bjwNNCbGmFTGRlWcE-AlhpU",
  projectId: "foound",
  //authDomain: "<YOUR-AUTH-DOMAIN>",
  databaseURL: "https://foound.firebaseio.com",
  storageBucket: "foound.appspot.com",
};
const googleConfig = {
  apiKey: "", //
};
const mapboxConfig = {
  tileLayer: "",
  maxZoom: "",
  defaultZoom: "",
  center: [],
};
const youtubeSearchApi = "";
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  baseUrl,
  apiUrl,
  qrcodeUrl,
  imgUrl,
  assetsUrl,
  jsonFolder,
  client,
  clientSubdomain,
  clientLogoUrl,
  clientHomeUrl,
  passwordForgotUrl,
  i18Url,
};
