import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Layout, Switch } from "antd";
import appActions from "redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarLang from "./TopbarLang";
import TopbarWrapper from "./topbar.style";
import themes from "settings/themes";
import { themeConfig } from "settings";
import isInApp from "../../helpers/isInApp";
import { apiUrl, client } from "settings";
import Image from "image/openWindow.svg";
import getWebsiteDomain from "helpers/getWebsiteDomain";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  render() {
    const { toggleCollapsed, businessInfo } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
    };

    const urlJson = `${apiUrl}/business/${this.props.businessInfo.uuid}/command/json`;

    let url = `https://${businessInfo.domain}.${getWebsiteDomain(
      businessInfo
    )}`;

    console.log("TOPBAR");
    console.log(url);
    console.log(businessInfo.whitelabel);

    if (
      typeof businessInfo.webapp_url === "string" &&
      businessInfo.webapp_url.length > 5
    ) {
      url = businessInfo.webapp_url;
    }

    if (isInApp() === true) {
      return false;
    }
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            {isInApp() === false && (
              <button
                className={
                  collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                }
                style={{ color: customizedTheme.textColor }}
                onClick={toggleCollapsed}
              />
            )}
          </div>

          <ul className="isoRight">
            <li className="isoUser">
              <a href={url} target="_blank" rel="noreferrer">
                <img alt="#" style={{ heigt: 20, width: 20 }} src={Image} />
              </a>
            </li>

            <li className="isoUser">
              <a href={urlJson} target="_blank" type="primary" rel="noreferrer">
                <Icon type="sync" />
              </a>
            </li>

            <li className="isoUser">
              {client !== "general" ? `env ${client}` : ""}
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "flag" })}
              className="isoUser"
            >
              <TopbarLang />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    businessInfo: state.BusinessInfo,
  }),
  { toggleCollapsed }
)(Topbar);
